import React from "react"
import classes from "./MainButton.module.scss"
import { AnchorLink } from "@jamesmallette/gatsby-plugin-anchor-links"

const MainButton = ({
  children,
  link,
  to,
  type = "button",
  anchor = false,
  onClick,
  email,
}) => {
  if (!anchor) {
    return link ? (
      <button
        className={classes.MainButton}
        type={type || "button"}
        onClick={onClick}
        tabIndex="-1"
      >
        <a href={link} className={`${email ? "mailtoui" : ""}`}>
          {children}
        </a>
      </button>
    ) : (
      <button
        className={classes.MainButton}
        type={type || "button"}
        onClick={onClick}
        style={{ padding: "var(--space-sm) var(--space-lg)" }}
      >
        {children}
      </button>
    )
  }
  if (anchor) {
    return (
      <button
        className={`${classes.MainButton} `}
        type={type || "button"}
        onClick={onClick}
        tabIndex="-1"
      >
        <AnchorLink to={to}>{children}</AnchorLink>
      </button>
    )
  }
}

export default MainButton
